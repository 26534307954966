import React from "react"

import image from "../images/logo.svg"
import example from "../images/example.png"

const Main = () => (
  <div className="Main">
    <img src={image} />
    <h2>Threaditions is now available on Etsy!</h2>
    <p>Visit our <a href="https://www.etsy.com/shop/ThreaditionsShop">Etsy Store</a> to view our collection.</p>
    <img className="Example" src={example} />
  </div>
)

export default Main
